//
//
//
//
//
//
//
//
//

import { get } from 'lodash'

export default {
    props: {
        section: {
            type: Object,
            required: true
        }
    },
    methods: { get }
}
