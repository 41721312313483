//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { filter, get, isEmpty, map, size } from 'lodash'

export default {
    props: {
        items: {
            type: Array,
            default: () => []
        },
        backgroundClasses: {
            type: String,
            default: 'bg-true-gray-100 lg:bg-transparent'
        }
    },
    data () {
        return {
            activeIndex: 0
        }
    },
    computed: {
        swiper () {
            return this.$refs.swiper.$swiper
        },
        swiperOptions () {
            return {
                init: false,
                breakpoints: {
                    1024: {
                        slidesPerView: 3.5,
                    },
                },
                centeredSlides: true,
                loop: true,
                slidesPerView: 1.5,
                pagination: {
                    el: `#swiper-pagination-${this._uid}`,
                    clickable: true,
                    renderBullet (index, className) {
                        return `<span class="${className} swiper-pagination-bullet-custom"></span>`
                    },
                },
                navigation: {
                    prevEl: `#swiper-prev-${this._uid}`,
                    nextEl: `#swiper-next-${this._uid}`
                }
            }
        },
        slides () {
            return filter(map(this.items, this.item2slide))
        },
        activeSlide () {
            return size(this.slides) ? this.slides[this.activeIndex] : null
        }
    },
    methods: {
        get,
        isEmpty,
        swiperSlidePrev () {
            this.swiper.slidePrev()
        },
        swiperSlideNext () {
            this.swiper.slideNext()
        },
        onSlideChange () {
            this.activeIndex = this.swiper.realIndex
        },
        item2slide (item) {
            if (get(item, 'sys.contentType.sys.id') === 'page') {
                const page = item

                return {
                    page,
                    kicker: this.pageCategory(page),
                    title: get(page, 'fields.title'),
                    description: get(page, 'fields.description'),
                    date: get(page, 'fields.date') ? this.$dateFns.format(new Date(get(page, 'fields.date')), 'y.M.d') : null
                }
            }

            if (get(item, 'sys.contentType.sys.id') === 'item') {
                const page = get(item, 'fields.reference')

                return {
                    page,
                    thumbnail: get(item, 'fields.media'),
                    kicker: get(item, 'fields.kicker', this.pageCategory(page)),
                    title: get(item, 'fields.title', get(page, 'fields.title')),
                    description: get(item, 'fields.description', get(page, 'fields.description')),
                    date: get(page, 'fields.date') ? this.$dateFns.format(new Date(get(page, 'fields.date')), 'y.M.d') : null
                }
            }

            return null
        }
    },
    mounted () {
        this.swiper.init()
    }
}
